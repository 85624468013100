<mat-card class="card-container" [ngClass]="cardClass">
  <mat-card-header class="card-header" *ngIf="heading || description"> 
    <mat-card-title *ngIf="heading">{{ heading }}</mat-card-title> 
    <mat-card-subtitle *ngIf="description"> {{ description }}</mat-card-subtitle> 
  </mat-card-header>

  <mat-card-content class="card-content">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
