//Angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// 3rd Party dependencies
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Zonar Pattern Library
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { Options } from '@zonar-ui/auth/lib/options/options';
import { SidenavModule } from '@zonar-ui/sidenav';
import { TranslateModule, ZonarUiI18nModule } from '@zonar-ui/i18n';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';
import { ZonarUiNotificationsModule } from '@zonar-ui/notifications';
import { ZonarUiFooterModule } from '@zonar-ui/footer';

// App-level Imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { CoreModule } from '@app/modules/core/core.module';
import { AlertSetupPageComponent } from './pages/alert-setup/alert-setup.page.component';
import { AlertSubscriptionFormComponent } from './components/alert-subscription-form/alert-subscription-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertsListComponent } from './components/alerts-list/alerts-list.component';
import { AllAlertsPageComponent } from './pages/all-alerts/all-alerts.page.component';
import { AlertInfoRowComponent } from './components/alert-info-row/alert-info-row.component';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ENV, getEnv } from '@environments/environment.provider';

// Material Component Modules
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';

import { AssetFilterComponent } from '@zonar-ui/filter';
import { MyAlertsPageComponent } from './pages/my-alerts/my-alerts.page.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { NoPermissionPageComponent } from './pages/no-permission/no-permission.page.component';
import { CardComponent } from './components/card/card.component';
import { CustomScheduleComponent } from './components/custom-schedule/custom-schedule.component';

const zonarOwnerIdOptions: Options = {
  zonarOwnerIdInterceptor: {
    requiresZonarOwnerId: [environment.userPreferenceBase]
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AlertSetupPageComponent,
    AlertSubscriptionFormComponent,
    AlertsListComponent,
    AllAlertsPageComponent,
    AlertInfoRowComponent,
    DeleteConfirmationDialogComponent,
    WithLoadingPipe,
    SearchFilterPipe,
    MyAlertsPageComponent,
    NotificationSettingsComponent,
    DialogComponent,
    NoPermissionPageComponent,
    CardComponent,
    CustomScheduleComponent,
  ],
  imports: [
    AppRoutingModule,
    AssetFilterComponent,
    AuthModule.forRoot({
      audience: environment.auth.audience,
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      max_age: 36000,
      useRefreshTokens: environment.auth.useRefreshTokens,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.apiBase.url}/*`,
          `${environment.coreEntityApiBase.url}/*`,
          /* allowing beta2 is necessary for apis on beta versions (e.g., settings) */
          `${environment.coreEntityApiBase.url}beta2/*`,
          `${environment.userPreferenceBase}/*`,
        ]
      }
    }),
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    SidenavModule.forRoot({ stage: environment.authEnv || null }),
    TranslateModule.forRoot({
      defaultLanguage: 'en-US'
    }),
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    ZonarUICoreModule,
    ZonarUiFooterModule,
    ZonarUiI18nModule,
    ZonarUiProgressSpinnerModule,
    ZonarUIMaterialModule,
    ZonarUiSearchableDropdownModule,
    ZonarUiNotificationsModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  providers: [
    { provide: 'region', useValue: environment.region },
    { provide: 'options', useValue: zonarOwnerIdOptions },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },

    { provide: 'applicationId', useValue: environment.auth.applicationId },
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: true },
    { provide: 'logoutReturnToUrl', useValue: window.location.origin },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: ENV, useFactory: getEnv }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
