<a class="u-text--blue u-text--small back-link" [routerLink]="'/'">&#60; Back to Manage Alerts</a>

<div class="title-page flex justify-between items-center">
  <h1>{{ isCreateNewMode ? 'Create Alert' : 'Edit Alert' }}</h1>

  <div *ngIf="!isCreateNewMode">
    <button mat-stroked-button color="accent" type="button" class="clone-button" (click)="cloneAlert()">
      <mat-icon>control_point_duplicate</mat-icon>
      <span class="u-text--nomal u-text--bold">Clone</span>
    </button>
  </div>
</div>

<form [formGroup]="alertForm" class="flex flex-column">
  <app-card [cardClass]="'first-card'">
    <div class="content-section-row default-size">
      <!-- 1. Alert Name Input -->
      <mat-form-field>
        <mat-label>Alert name</mat-label>
        <input formControlName="name" type="text" matInput placeholder="Alert name" [errorStateMatcher]="matcher" />
        <mat-error data-e2e="name-error" *ngIf="name.hasError('maxlength') && !name.hasError('required')">
          The name can not be more than 50 characters
        </mat-error>
      </mat-form-field>

      <!-- 2. Alert Type Dropdown-->
      <div *ngIf="alertTypes$ | withLoading | async as alertTypes" class="alert-type-container">
        <ng-template [ngIf]="alertTypes.value">
          <zui-searchable-dropdown
            label="Alert type"
            [options]="alertTypes.value.alertTypeOptions"
            [isMultiple]="false"
            [z_formGroup]="alertForm"
            [selectOptionsControlName]="'type'"
            (selected)="handleAlertTypeSelection($event, alertTypes.value.severityOptionsByType)"
          >
          </zui-searchable-dropdown>
          <div
            class="alert-description"
            *ngIf="alertForm.value.type && alertTypes.value.alertDescriptionByType[alertForm.value.type]"
            [innerHTML]="alertTypes.value.alertDescriptionByType[alertForm.value.type]"
          ></div>
    
          <div *ngIf="alertForm.value.type">
            <div
              class="flex flex-row items-center min-unauthorized-distance-container"
              *ngIf="alertForm.value.type === unauthorizedUsageAlertTypeValue"
            >
              <span>Alert after asset has driven </span>
              <mat-form-field hideRequiredMarker class="min-unauthorized-distance">
                <input
                  required
                  formControlName="unauthorizedUsageDistance"
                  type="text"
                  matInput
                  placeholder="0"
                  (keypress)="omitNumbersFromInput($event)"
                  maxlength="1"
                />
              </mat-form-field>
              <span>miles</span>
            </div>
    
            <span *ngIf="alertTypesWithRadioButtons.includes(alertForm.value.type)">
              <fieldset class="flex flex-column justify-between severity-options-container">
                <legend>Threshold options</legend>
                <mat-radio-group formControlName="severity">
                  <mat-radio-button
                    *ngFor="let severityOption of alertTypes.value.severityOptionsByType[alertForm.value.type]"
                    [value]="severityOption.value"
                    (change)="handleToggleStatusChange()"
                  >
                    <div class="flex flex-row justify-between items-center">
                      <div class="custom-icon {{ severityOption.icon }}">
                        <mat-icon>{{ severityOption.icon }}</mat-icon>
                      </div>
                      <div class="flex flex-column justify-between severity-content">
                        <span class="severity-title">{{ severityOption.title }}</span>
                        <span class="severity-description">{{ severityOption.description }}</span>
                      </div>
                    </div>
                  </mat-radio-button>
                </mat-radio-group>
                <ng-container *ngIf="displayCTSLink && (isManagedThresholds$ | async)">
                  <a *ngIf="alertTypes.value.isThresholdUrlShowedByType[alertForm.value.type]" [href]="thresholdSettingsLink"
                    >Update threshold settings</a
                  >
                </ng-container>
              </fieldset>
            </span>
          </div>
        </ng-template>
        <ng-template [ngIf]="alertTypes.error">
          <div class="dropdown-error-container u-margin__bottom--medium">Error loading alert types</div>
        </ng-template>
        <ng-template [ngIf]="alertTypes.loading">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'border-radius': '3px',
              height: '40px',
              'background-color': '#E3E3E3'
            }"
          ></ngx-skeleton-loader>
        </ng-template>
      </div>
    </div>
  </app-card>

  <app-card [heading]="'Custom schedule'"
    [description]="'Alerts will be sent on all days & times unless custom schedule(s) are created.'"
    *ngIf="displayCustomSchedule">
    <div class="custom-schedule-list-container">
      <div formArrayName="customSchedules"
        *ngFor="let schedule of customScheduleList.controls; let i = index"
        class="custom-schedule-list">
        <app-custom-schedule [scheduleForm]="customScheduleList.at(i)"
          [matcher]="matcher"
          (clickedRemoveButton)="removeCustomSchedule(i)"></app-custom-schedule>
      </div>
      <button mat-stroked-button
        color="accent"
        class="add-custom-schedule-button"
        (click)="addCustomSchedule()">
        <mat-icon>add</mat-icon>
        <span>Add schedule</span>
      </button>
    </div>
  </app-card>

  <app-card [heading]="'Assets & Recipients'" [description]="'Select which assets you\'d like to receive alerts for and who should reveive the alerts.'" [cardClass]="'third-card'">
    <div class="content-section-row default-size">
      <!-- 3. Asset dropdown filter -->
      <span *ngIf="assetsFilterDropdown$ | withLoading | async as assets">
        <ng-template [ngIf]="assets.value">
          <zui-asset-filter
            [companyId]="companyId$ | async"
            [zonarOwnerId]="null"
            [showApplyButton]="false"
            [compactMode]="false"
            (selectedAssetList)="handleAssetDropdownChange($event)"
            [preSelectedAssets]="assets.value.preSelect"
            [assetOnlyMode]="assets.value.assetSource"
            [uniqueDropdownName]="'Asset *'"
            class="asset-filter"
          >
          </zui-asset-filter>
        </ng-template>
    
        <ng-template [ngIf]="assets.error"
          ><div class="dropdown-error-container u-margin__bottom--medium">Error loading assets</div></ng-template
        >
        <ng-template [ngIf]="assets.loading">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'border-radius': '3px',
              height: '40px',
              'background-color': '#E3E3E3'
            }"
          ></ngx-skeleton-loader>
        </ng-template>
      </span>
      <input type="hidden" formControlName="assets" />
      <!-- 4. Recipients dropdown filter -->
      <div *ngIf="recipients$ | withLoading | async as recipients">
        <ng-template [ngIf]="recipients.value">
          <zui-searchable-dropdown
            label="Recipients"
            [options]="recipients.value"
            [isMultiple]="true"
            [z_formGroup]="alertForm"
            [selectOptionsControlName]="'recipients'"
            [hideRequiredMarker]="false"
          >
          </zui-searchable-dropdown>
        </ng-template>
        <ng-template [ngIf]="recipients.error"
          ><div class="dropdown-error-container u-margin__bottom--medium">Error loading recipients</div></ng-template
        >
        <ng-template [ngIf]="recipients.loading">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'border-radius': '3px',
              height: '40px',
              'background-color': '#E3E3E3'
            }"
          ></ngx-skeleton-loader>
        </ng-template>
      </div>
    </div>
  </app-card>

  <app-card *ngIf="!isCreateNewMode">
    <div class="content-section-row flex flex-row justify-between active-status-toggle-container u-margin__bottom--medium striped">
      <mat-slide-toggle formControlName="isActive" (change)="handleToggleStatusChange()">
        {{ alertForm.value.isActive ? 'Active' : 'Inactive' }}
      </mat-slide-toggle>
      <mat-icon #tooltip="matTooltip" matTooltip="Recipients will not receive alerts when set to inactive" [matTooltipPosition]="'above'">
        info_outline
      </mat-icon>
    </div>
  </app-card>
</form>

<div class="page-actions">
  <div class="flex justify-between actions-container">
    <div>
      <button *ngIf="!isCreateNewMode"
        type="button"
        class="delete-button u-text--bold"
        mat-stroked-button 
        color="accent"
        (click)="deleteAlert()"
      >
        Delete
      </button>
    </div>
    <div>
      <button
        type="button"
        color="accent"
        mat-stroked-button
        class="cancel-button"
        routerLink="/"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        type="submit"
        data-e2e="save-button"
        color="accent"
        (click)="submit()"
        [disabled]="!alertForm.valid || !alertForm.touched || !alertForm.dirty || isDropdownLoadingError || isSaving"
      >
        {{ isSaving ? 'Saving' : 'Save' }}
      </button>
    </div>
  </div>
</div>
