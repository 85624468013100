import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@environments/environment.provider';
import { Observable, throwError, of, forkJoin, combineLatest } from 'rxjs';
import { CompanyService } from './company-store.service';
import { filter, map, switchMap, catchError, tap, finalize, take } from 'rxjs/operators';
import { AdminService } from '@app/services/admin.service';
import { ECompanyLoginMode, PermissionsService } from '@zonar-ui/auth';
import { IUser } from '@zonar-ui/auth/lib/models/user.model';
import { IUserProfile } from '@zonar-ui/auth/lib/models/user-profile.model';
import { IUserGroupPolicy } from '@zonar-ui/auth/lib/models/user-group-policy.model';
import { UtilitiesService } from './utilities.service';
import { PaginatedResponse } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class RecipientApiHttpService {
  USERS_API_URL = this.env.coreEntityApiBase.url + '/users';
  USERPROFILES_API_URL = this.env.coreEntityApiBase.url + '/userprofiles';
  APP_ID = this.env.auth.applicationId;
  ACTIVITY_FEED_APP_ID = this.env.activityFeedBase.applicationId;

  constructor(
    @Inject(ENV) private env: any,
    private _companyService: CompanyService,
    private _httpService: HttpClient,
    private _adminService: AdminService,
    private _permissionsService: PermissionsService,
    private _utilitiesService: UtilitiesService
  ) {}

  getRecipients(): Observable<any> {
    return this._permissionsService.getIsPermissionsLoaded().pipe(
      filter((isLoaded) => isLoaded), // Ensure permissions are loaded before continuing
      switchMap(() =>
        this._utilitiesService.getCompanyLoginMode().pipe(
          switchMap((loginMode) => {
            if (loginMode === ECompanyLoginMode.GROUP_POLICY) {
              return this._getRecipientsForGroupPolicyContext();
            } else {
              return this._getRecipientsForUserProfileContext();
            }
          })
        )
      )
    );
  }

  private _getRecipientsForUserProfileContext(): Observable<any> {
    return this._companyService.currentCompany$.pipe(
      filter((company) => !!company),
      switchMap((company) => {
        return this._adminService.isCompanyAdmin$.pipe(
          switchMap((isCompanyAdmin: boolean) => {
            if (isCompanyAdmin) {
              return this.getAllUsers(company.value).pipe(map((users) => ({ users })));
            } else {
              return this._adminService.isPartialAdmin$.pipe(
                switchMap((isPartialAdmin: boolean) => {
                  if (isPartialAdmin) {
                    const allUsers$ = this.getAllUsers(company.value);
                    const userProfiles$ = this.getUserDivisions(company.value).pipe(
                      switchMap((divisions) => {
                        if (!divisions || divisions.length === 0) return of([]);
                        return this.getDivisionsUserProfiles(divisions, company.value);
                      })
                    );
                    return forkJoin({ allUsers: allUsers$, userProfiles: userProfiles$ });
                  } else {
                    console.log('Neither company admin nor partial admin - throwing error');
                    return throwError(new Error('User is not authorized to view recipients.'));
                  }
                })
              );
            }
          })
        );
      }),
      map((result: { users: IUser[] } | { allUsers: IUser[]; userProfiles: IUserProfile[] }) => {
        let users: IUser[] = [];
        if ('users' in result) {
          users = result.users;
        } else if ('allUsers' in result && 'userProfiles' in result) {
          // print all users and all userprofiles to console
          console.log('Partial Admin, allUsers:', result.allUsers);
          console.log('Partial Admin, userProfiles:', result.userProfiles);

          // filter out all the userProfiles whose divisions is a an emapty array
          result.userProfiles = result.userProfiles.filter((userProfile) => userProfile.divisions.length > 0);
          console.log('Partial Admin, userProfiles after filtering:', result.userProfiles);

          users = this.mapUserProfilesToUsers(result.allUsers, result.userProfiles);
        }
        const recipients = users.map((user: IUser) => {
          const userId = user.id!;
          const name = this._capitalizeFullName(`${user.firstName} ${user.lastName}`);
          return { title: name, value: userId };
        });
        recipients.sort((a, b) => a.title.localeCompare(b.title));
        console.log('Final Recipients (User Profile):', recipients);
        return recipients;
      }),
      catchError((error) => {
        console.error('Failed to load recipients', error);
        return throwError(new Error('Failed to load recipients'));
      })
    );
  }

  private _getRecipientsForGroupPolicyContext(): Observable<any> {
    return this._getUserGroupPoliciesFilteredByCompanyId().pipe(
      switchMap((response) => {
        const uniqueTenantIdsSet = new Set();
        response.forEach((policy) => {
          uniqueTenantIdsSet.add(policy.tenant.id);
        });

        const uniqueTenantIdsArray = Array.from(uniqueTenantIdsSet);
        let uniqueTenantIdsObservables = [];
        uniqueTenantIdsArray.forEach((id) => {
          uniqueTenantIdsObservables.push(this._getRecipientsByTenant(id));
        });
        return combineLatest(...uniqueTenantIdsObservables).pipe(
          map((val) => {
            const output = this._utilitiesService.deduplicateArrayByValue(val.flat(), 'value');
            console.log('Final Recipients (Group Policy):', output);
            return output;
          }),
          catchError((err) => {
            throw new Error(`Unable to get recipients for Group Policy context: ${err.message}`);
          })
        );
      })
    );
  }

  private _getUserGroupPoliciesFilteredByCompanyId(): Observable<Array<IUserGroupPolicy>> {
    return this._permissionsService.getUserGroupPolicies().pipe(
      filter((x) => !!x),
      switchMap((userPolicies) => {
        return this._companyService.currentCompany$.pipe(
          map((company) => {
            return userPolicies.filter((policy) => policy.policy.companyId === company.value);
          })
        );
      })
    );
  }

  private _getRecipientsByTenant(tenantId) {
    const groupsApiUrl = `${this.env.coreEntityApiBase.url}/groups?tenantId=${tenantId}`;
    return this._utilitiesService.getAllPages(groupsApiUrl).pipe(
      map((response: Array<any>) => {
        const membersArrays = [];
        response.forEach((group) => {
          membersArrays.push(group.members);
        });
        const flattenedMembers = membersArrays.flat();
        const formattedRecipients = flattenedMembers.map((member) => {
          return {
            title: member.firstName + ' ' + member.lastName,
            value: member.id
          };
        });
        return formattedRecipients;
      })
    );
  }

  private mapUserProfilesToUsers(allUsers: IUser[], userProfiles: IUserProfile[]): IUser[] {
    const allUsersMap = new Map<string, IUser>();
    allUsers.forEach((user: IUser) => {
      if (user.id) allUsersMap.set(user.id, user);
    });
    const uniqueUserIds = new Set<string>();
    userProfiles.forEach((userProfile: IUserProfile) => {
      const userId = userProfile.userId;
      if (userId) uniqueUserIds.add(userId);
    });
    return Array.from(uniqueUserIds)
      .map((userId) => allUsersMap.get(userId))
      .filter((user): user is IUser => user !== undefined);
  }

  private getUserDivisions(companyId: string): Observable<string[]> {
    console.log('getUserDivisions called with companyId:', companyId);
    return this._permissionsService.getUser().pipe(
      take(1), // Ensure the observable completes after emitting one value
      // tap(user => console.log('getUserDivisions: got user:', user)),
      switchMap((user: IUser) => {
        const userId = user.id!;
        const url = `${this.USERS_API_URL}/${userId}/companies/${companyId}/divisions?applicationId=${this.ACTIVITY_FEED_APP_ID}&allChildren=true`;
        return this._httpService.get<{ divisions: string[] }>(url).pipe(
          // tap(response => console.log('getUserDivisions: got response:', response)),
          map((response) => response.divisions || []),
          tap((divisions) => console.log('getUserDivisions in recipients, divisions:', divisions))
        );
      }),
      catchError((error) => {
        console.error('Error fetching user divisions', error);
        return of([]); // Return empty array on error
      }),
      finalize(() => console.log('getUserDivisions: completed'))
    );
  }

  private getAllUsers(companyId: string): Observable<IUser[]> {
    const perPage = 1000;
    const userUrl = `${this.USERS_API_URL}?companyId=${companyId}&status=ACTIVE`;

    // console.time('getAllUsers - Parallel'); // Start timing for the parallel method

    // Step 1: Perform a HEAD request to get the total count from the headers
    return this._httpService.head(userUrl, { observe: 'response' }).pipe(
      switchMap((response) => {
        const totalCount = Number(response.headers.get('x-total-count')); // Get the total count from the header
        const totalPages = Math.ceil(totalCount / perPage); // Calculate the total number of pages

        if (totalCount === 0) {
          console.log(`No users found for company ${companyId}`);
          return of([]); // Return empty array when no users found
        }

        console.log(`Total Users: ${totalCount}, Total Pages: ${totalPages}`); // Log total count and pages

        // Step 2: Create an array of observables for each page
        const pageRequests: Observable<PaginatedResponse<IUser>>[] = [];
        for (let page = 1; page <= totalPages; page++) {
          const paginatedUrl = `${this.USERS_API_URL}?companyId=${companyId}&status=ACTIVE`;
          pageRequests.push(this._utilitiesService.getPaginatedData<IUser>(paginatedUrl, page, perPage));
        }

        // Step 3: Use forkJoin to run all the requests in parallel
        return forkJoin(pageRequests);
      }),
      // Step 4: Merge the results from all the pages into a single array
      map((results: PaginatedResponse<IUser>[]) => {
        // console.timeEnd('getAllUsers - Parallel'); // End timing for the parallel method
        return results.reduce((acc, result) => acc.concat(result.items), [] as IUser[]);
      }),
      catchError((error) => {
        // console.timeEnd('getAllUsers - Parallel'); // End timing in case of error
        console.error('Error fetching all users (Parallel):', error);
        return throwError(() => new Error('Error fetching all users (Parallel)'));
      })
    );
  }

  private getDivisionsUserProfiles(divisions: string[], companyId: string): Observable<IUserProfile[]> {
    if (!divisions || divisions.length === 0) {
      return of([]);
    }

    // Create observables for each division by making individual API calls for each division ID
    const observables = divisions.map((divisionId) => {
      // Build the API URL with a single division ID (implicitDivisionId)
      const userProfilesUrl = `${this.USERPROFILES_API_URL}?companyId=${companyId}&implicitDivisionId=${divisionId}`;

      // Fetch all pages for the division
      return this._utilitiesService.getAllPages<IUserProfile>(userProfilesUrl);
    });

    // Fetch all division-related user profiles in parallel
    return forkJoin(observables).pipe(
      map((results) => {
        // Flatten the results into a single array
        return results.reduce((acc, val) => acc.concat(val), []);
      })
    );
  }

  private _capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  private _capitalizeFullName(name: string): string {
    return name.split(' ').map(this._capitalizeFirstLetter).join(' ');
  }
}
