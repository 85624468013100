<div class="custom-schedule-container">
  <div class="content">
    <form [formGroup]="scheduleForm">
      <ng-container *ngTemplateOutlet="dayTimeField; context: { $implicit: 'Activity starting', dayControl: scheduleForm.controls.startDay, timeControl: scheduleForm.controls.startTime }"></ng-container>
      <ng-container *ngTemplateOutlet="dayTimeField; context: { $implicit: 'Activity ending', dayControl: scheduleForm.controls.endDay, timeControl: scheduleForm.controls.endTime }"></ng-container>
    </form>
    <div class="flex flex-row justify-end items-center">
      <button mat-button
        color="accent"
        class="remove-custom-schedule-button"
        (click)="clickedRemoveButton.emit($event)">
        <mat-icon>delete</mat-icon>
        <span>Remove</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>

<ng-template #dayTimeField let-title let-dayControl="dayControl" let-timeControl="timeControl">
  <div class="title">{{ title }}</div>
  <div class="flex flex-row justify-between items-center">
    <ng-container *ngTemplateOutlet="selectField; context: { $implicit: dayControl, label: 'Day', options: dayOptions }"></ng-container>
    <ng-container *ngTemplateOutlet="selectField; context: { $implicit: timeControl, label: 'Time', options: timeOptions }"></ng-container>
  </div>
</ng-template>

<ng-template #selectField let-control let-label="label" let-options="options">
  <mat-form-field appearance="outline"
    hideRequiredMarker="true"
    [style.width.%]="48">
    <mat-label>{{ label }}</mat-label>
    <mat-select focused="false"
      [formControl]="control"
      [errorStateMatcher]="matcher">
      <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
