import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DAY_OPTIONS, TIME_OPTIONS } from '@app/constants';

@Component({
  selector: 'app-custom-schedule',
  templateUrl: './custom-schedule.component.html',
  styleUrls: ['./custom-schedule.component.scss']
})
export class CustomScheduleComponent {
  @Input() scheduleForm: FormGroup;
  @Input() matcher;
  @Output() clickedRemoveButton = new EventEmitter();

  dayOptions = DAY_OPTIONS;
  timeOptions = TIME_OPTIONS;

  constructor() { }

}
