import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-setup-page',
  templateUrl: './alert-setup.page.component.html',
  styleUrls: ['./alert-setup.page.component.scss']
})
export class AlertSetupPageComponent {
  isCloneMode = !!(this.route.snapshot?.data?.cloneMode ?? false);
  constructor(private route: ActivatedRoute) {}
}
