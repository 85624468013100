<div class="no-permission-page-container flex flex-column justify-between">
  <a [href]="alertsLink" class="u-text--blue u-text--small">&#60; Back to Alerts</a>

  <div class="flex flex-column justify-between items-center">
    <div class="icon"><mat-icon>not_interested</mat-icon></div>
    <h1 class="title">Not Authorized</h1>
    <div class="description">User must have company admin access to manage alerts.</div>
  </div>

  <!-- Extra div for convenient center by flex -->
  <div></div> 
</div>
