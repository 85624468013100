<div class="flex flex-row justify-start items-center back-link">
  <a [href]="alertsLink" class="u-text--blue u-text--small">&#60; Back to Alerts</a>
</div>
<div class="flex flex-row justify-between u-margin__bottom--large items-center">
  <h1>Manage Alerts</h1>
  <button *ngIf="isAdmin$ | async" mat-flat-button type="text" color="accent" routerLink="add">New Alert</button>
</div>

<div *ngIf="isManagedThresholds$ | async" class="page-description u-text--black-base u-margin__bottom--large">
  Manage the alerts for your entire company here. Assign alerts to persons responsible for different areas of your business.
  <span *ngIf="isAdmin$ | async">
    <ng-container *ngIf="displayCTSLink">
      If you would like to manage your severity levels, visit the
      <a class="u-text--blue u-text--bold" [href]="thresholdSettingsLink">threshold settings page.</a>
    </ng-container>
  </span>
</div>

<mat-form-field class="search-bar">
  <zui-icon matPrefix icon="search"></zui-icon>
  <input [formControl]="searchInput" matInput placeholder="Search" />
  <button mat-button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchInput.reset()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<app-alerts-list [searchText]="searchInput.value" [alertMetadata]="alertMetadata" [isEditorViewEnabled]="true"></app-alerts-list>
