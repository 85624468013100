import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  saveErrorText,
  saveSuccessText,
  deleteErrorText,
  deleteSuccessText,
  COMPANIES_ENTITY_TYPE,
  TOAST_MESSAGE_DURATION_SECONDS,
  UNAUTHORIZED_USAGE_ALERT_TYPE
} from '@app/constants';
import { Alert, Company } from '@app/models';
import { SubscriptionApiHttpService } from '@app/services/subscription-api.http.service';
import { AssetApiHttpService } from '@app/services/asset-api.http.service';
import { RecipientApiHttpService } from '@app/services/recipient-api.http.service';
import { CompanyService } from '@app/services/company-store.service';
import { ReplaySubject, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, finalize, filter, map, switchMap, take, startWith, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '@app/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FeatureToggleService } from '@app/services/feature-toggle.service';
import { TIME_OPTIONS } from '@app/constants';
import * as moment from 'moment-timezone';
import { ENV } from '@environments/environment.provider';
import { AdminService } from '@app/services/admin.service';
import { DOCUMENT } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';

class CommonStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl) {
    return control && (control.touched || control.dirty) && control.invalid;
  }
}

@Component({
  selector: 'app-alert-subscription-form',
  templateUrl: './alert-subscription-form.component.html',
  styleUrls: ['./alert-subscription-form.component.scss']
})
export class AlertSubscriptionFormComponent implements OnInit, OnDestroy {
  @Input() isCloneMode = false;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(ENV) private env: any,
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _subscriptionApiService: SubscriptionApiHttpService,
    private _assetApiService: AssetApiHttpService,
    private _recipientApiService: RecipientApiHttpService,
    private _companyService: CompanyService,
    private _notifications: ZonarUiNotificationsService,
    private _router: Router,
    private _dialog: MatDialog,
    private _adminService: AdminService,
    private _featureToggleService: FeatureToggleService,
    private _renderer: Renderer2,
  ) {}
  @Input() alert: Alert;
  public alertForm: FormGroup;
  id: string;
  companyId: string;
  isAddMode: boolean;

  isSaving: boolean = false;
  isDropdownLoadingError = false;

  displayCustomSchedule;
  displayCTSLink;

  assets$ = new ReplaySubject<any[]>(1);
  isManagedThresholds$ = this._adminService.isManagedThresholds$;

  companyId$ = this._companyService.currentCompany$.pipe(map((company) => company.value));

  unauthorizedUsageAlertTypeValue = UNAUTHORIZED_USAGE_ALERT_TYPE;
  thresholdSettingsLink: string = this.env.thresholdSettingsBase.link;

  additionalSeverityOptionsInfo: Record<string, string>[] = [
    { title: 'Critical events', icon: 'info' },
    { title: 'Minor + critical events', icon: 'report_problem' }
  ];

  // NOTE: needs to be updated each time a new alert type with radio button is added
  alertTypesWithRadioButtons = [
    'ev_soc_low',
    'low_battery',
    'fault_code',
    'low_battery_test_270',
    'low_battery_test',
    'depth_level_test_270',
    'evir-defect',
    'posted_speed',
    'idle_stop_critical'
  ];

  matcher = new CommonStateMatcher();

  get name() {
    return this.alertForm.get('name');
  }

  get isCreateNewMode() {
    return this.isAddMode || this.isCloneMode;
  }

  get customScheduleList() {
    return this.alertForm.controls.customSchedules as FormArray;
  }

  preselectedAssets$ = new ReplaySubject<any[]>(1);
  preselectedAssetsObs$ = this.preselectedAssets$.asObservable().pipe(filter((val) => !!val));

  defaultBlankFormValues = {
    name: '',
    type: null,
    assets: [],
    recipients: null,
    isActive: true,
    severity: '',
    customSchedules: [],
    allAssets: false,
    unauthorizedUsageDistance: '0'
  };

  assetOptions$ = this._companyService.currentCompany$.pipe(
    switchMap(() => {
      return this._assetApiService.getAssetsFromCache().pipe(
        map(({ assets }) => assets),
        catchError((err) => {
          this.isDropdownLoadingError = true;
          return throwError(err);
        })
      );
    })
  );

  recipients$ = this._companyService.currentCompany$.pipe(
    switchMap(() => {
      return this._recipientApiService.getRecipients().pipe(
        catchError((err) => {
          this.isDropdownLoadingError = true;
          return throwError(err);
        })
      );
    })
  );

  assetsFilterDropdown$ = this._companyService.currentCompany$.pipe(
    filter((company) => !!company),
    distinctUntilChanged((prev, curr) => prev.value === curr.value),
    switchMap(() =>
      combineLatest([
        this._assetApiService.getAssetsFromCache().pipe(filter(({ isComplete }) => !!isComplete), map(({ assets }) => assets)),
        this.preselectedAssets$
      ])
    ),
    map(([assetSource, preSelect]) => {
      return { assetSource, preSelect };
    }),
    catchError((err) => {
      this.isDropdownLoadingError = true;
      return throwError(err);
    })
  );

  alertTypes$ = this._companyService.currentCompany$.pipe(
    switchMap(() => this._subscriptionApiService.getAlertTypesFromCache()),
    map((alertTypes) =>
      alertTypes.results.reduce(
        (acc, alertType) => {
          let severityOptionsByType = acc.severityOptionsByType;
          let isThresholdUrlShowedByType = acc.isThresholdUrlShowedByType;
          if (alertType._source.event_selection && alertType._source.event_selection.length) {
            // Case: List of radio buttons
            const severitySelectors = alertType._source.event_selection.find((item) => item.field === 'severity').selector;
            const severityOptions = {
              [alertType._source.event_type]: severitySelectors.map((selector, index) => ({
                title: this.additionalSeverityOptionsInfo[index].title,
                description: selector.name,
                value: selector.id,
                icon: this.additionalSeverityOptionsInfo[index].icon
              }))
            };
            const isThresholdUrlShowed = { [alertType._source.event_type]: this.isAlertManagedByThresholdSetting(severitySelectors) };
            severityOptionsByType = { ...severityOptionsByType, ...severityOptions };
            isThresholdUrlShowedByType = { ...isThresholdUrlShowedByType, ...isThresholdUrlShowed };
          }

          return {
            alertTypeOptions: [...acc.alertTypeOptions, ...[{ title: alertType._source.name, value: alertType._source.event_type }]],
            severityOptionsByType: severityOptionsByType,
            alertDescriptionByType: { ...acc.alertDescriptionByType, [alertType._source.event_type]: alertType._source.description },
            isThresholdUrlShowedByType: isThresholdUrlShowedByType
          };
        },
        { alertTypeOptions: [], severityOptionsByType: {}, alertDescriptionByType: {}, isThresholdUrlShowedByType: {} }
      )
    ),
    catchError((err) => {
      this.isDropdownLoadingError = true;
      return throwError(err);
    })
  );

  ngOnInit() {
    this._renderer.addClass(this.document.body, 'form-screen');
    this.id = this.route.snapshot.params['id'];
    this._featureToggleService
      .isFeatureEnabled('am-custom-schedule')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((flag) => (this.displayCustomSchedule = flag));
    this._featureToggleService
      .isFeatureEnabled('cts-admin-link')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((flag) => (this.displayCTSLink = flag));

    this.isAddMode = !this.id;
    this._populateForm(this.defaultBlankFormValues);

    if (!this.isAddMode) {
      this._companyService.currentCompany$
        .pipe(
          filter((company) => !!company),
          distinctUntilChanged((prev, curr) => prev.value === curr.value),
          switchMap((company: Company) => {
            return this._subscriptionApiService.getSubscriptionById(this.id, company.value);
          }),
          switchMap((alert) => {
            const selectedAlert = alert[0];
            if (selectedAlert.allAssets) {
              return this._assetApiService.getAssetsFromCache().pipe(
                map(({ assets }) => {
                  const transformedAssets = this._buildSavedDropdownOptionsList(assets);
                  this.preselectedAssets$.next(transformedAssets);
                  return { ...selectedAlert, entities: assets };
                })
              );
            } else {
              return of(selectedAlert).pipe(
                map((selectedAlert) => {
                  const assets = this._buildSavedDropdownOptionsList(selectedAlert.entities);
                  this.preselectedAssets$.next(assets);
                  return selectedAlert;
                })
              );
            }
          }),
          take(1)
        )
        .subscribe((alert) => {
          const alertValues = this._buildFormWithExistingValues(alert);
          this._populateForm(alertValues);
          this._subscribeToTypeFormControlValueChanges(alert);
        });
    } else {
      this.preselectedAssets$.next([]);
      this._subscribeToTypeFormControlValueChanges(null);
    }
  }

  private isAlertManagedByThresholdSetting(selectors) {
    const lookupSettingKey = (logicSelector) => {
      if (logicSelector.settingsAPIKey) {
        return true;
      }

      if (Array.isArray(logicSelector.iterator)) {
        return logicSelector.iterator.some((selector) => lookupSettingKey(selector));
      }

      return false;
    };

    return selectors.some((selector) => lookupSettingKey(selector.comparisonLogics));
  }

  private _subscribeToTypeFormControlValueChanges(alert) {
    let startingValue;
    if (!!alert) {
      startingValue = alert.eventType.id;
    } else {
      startingValue = this.alertForm.value.type;
    }

    this.alertForm.controls['type'].valueChanges
      .pipe(
        startWith(startingValue),
        map((value) => this.addOrRemoveControlsBasedOnTypeSelection(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _convertUTCToLocal(day: string, time: string, timezone: string): string {
    const weekDay = moment().day(day).format('YYYY-MM-DD'); // Get the specific date for this week's day
    const dateTimeFormat = `${weekDay} ${time}`;
    const utcMoment = moment.tz(dateTimeFormat, 'YYYY-MM-DD hh:mm A', 'UTC');
    const localMoment = utcMoment.clone().tz(timezone);

    // Find the closest match in TIME_OPTIONS
    const formattedTime = localMoment.format('h:mm A');
    const closestTime = this._findClosestTimeOption(formattedTime);
    return `${localMoment.format('dddd')} ${closestTime}`;
  }

  private _findClosestTimeOption(time: string): string {
    // This function can be improved to find the closest match in TIME_OPTIONS
    // For simplicity, it returns the exact match or the first option as a fallback
    return TIME_OPTIONS.includes(time) ? time : TIME_OPTIONS[0];
  }

  private _buildFormWithExistingValues(alert) {
    const formObj: any = {
      name: this.isCloneMode ? '' : alert.eventType.name,
      type: alert.eventType.id,
      assets: this._buildSavedDropdownOptionsList(alert.entities),
      recipients: this._buildSavedDropdownOptionsList(alert.recipients),
      isActive: this.isCloneMode || alert.status === 'ACTIVE',
      customSchedules: this._buildCustomSchedules(alert),
      allAssets: alert.allAssets
    };

    if (alert.eventType.id === this.unauthorizedUsageAlertTypeValue) {
      formObj.unauthorizedUsageDistance = alert.configParams.distanceTravelled;
    } else if (alert.eventSelection && alert.eventSelection.length) {
      formObj.severity = alert.eventSelection?.find((item) => item.field === 'severity').activeSelectorId;
    }

    return formObj;
  }

  private _buildCustomSchedules(alert) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // We will get the value from 'customSchedules' field first,
    // if 'customSchedules' field doesn't exist, then fallback to 'customSchedule' field and convert it to an array
    // if both 'customSchedules' and 'customSchedule' fields don't exist, an empty array will be used.
    const schedules = alert.customSchedules
      ? alert.customSchedules
      : alert.customSchedule ? [alert.customSchedule] : [];

    return schedules.map(schedule => {
      const [startDay, startTime] = this._convertUTCToLocal(schedule.customStartTime.day, schedule.customStartTime.time, userTimezone).split(/ (.*)/);
      const [endDay, endTime] = this._convertUTCToLocal(schedule.customEndTime.day, schedule.customEndTime.time, userTimezone).split(/ (.*)/);

      return { startDay, startTime, endDay, endTime };
    });
  }

  handleToggleStatusChange() {
    // TODO: toggle slider is not marking the form as touched automatically. Investigate why.
    this.alertForm.markAsTouched();
  }

  private _populateForm(values) {
    this.alertForm = this.fb.group({
      name: [values.name, [Validators.required, Validators.maxLength(50)]],
      type: [values.type, Validators.required],
      assets: [values.assets, Validators.required],
      recipients: [values.recipients, Validators.required],
      isActive: [values.isActive],
      severity: [values.severity, Validators.required],
      customSchedules: this.fb.array([], this.multipleSchedulesValidator()),
      allAssets: [values.allAssets],
      unauthorizedUsageDistance: [values.unauthorizedUsageDistance]
    });
    values.customSchedules.forEach(schedule => this.addCustomSchedule(schedule));
  }

  private _buildSavedDropdownOptionsList(optionsList) {
    const optionIdsList = [];
    optionsList.forEach((option) => {
      // TODO: Remove this when https://zonarsystems.atlassian.net/browse/AA-611 has been completed
      if (option.type !== COMPANIES_ENTITY_TYPE) {
        optionIdsList.push(option.id);
      }
    });
    return optionIdsList;
  }

  submit() {
    this.isSaving = true;

    this._companyService.currentCompany$
      .pipe(
        switchMap((company: Company) => {
          this.companyId = company?.value;
          return this.assetOptions$.pipe(
            switchMap((assetOptions) =>
              this._adminService.isPartialAdmin$.pipe(
                take(1),
                map((isPartialAdmin) => {
                  const alert = this.alertForm.getRawValue();

                  // Check if partial admin and allAssets is selected
                  if (alert.allAssets && isPartialAdmin) {
                    alert.assets = assetOptions.map((option) => option.id); // Populate with all asset IDs
                    alert.allAssets = false; // Clear allAssets
                  } else if (!alert.allAssets && !isPartialAdmin) {
                    // allAssets value is true if number of selected assets equals total number of assets
                    // Double-checks whether all assets have been manually selected, as opposed to checking the All Assets checkbox
                    alert.allAssets = alert.assets.length === assetOptions.length;
                  }

                  return alert;
                })
              )
            )
          );
        }),
        switchMap((alert) => {
          return this.isCreateNewMode
            ? this._subscriptionApiService.post(alert, this.companyId)
            : this._subscriptionApiService.put(this.id, alert, this.companyId);
        }),
        take(1),
        finalize(() => (this.isSaving = false))
      )
      .subscribe(
        () => {
          this.alertForm.reset();
          this.showSuccessNotification(saveSuccessText);
          this._router.navigate(['/']);
        },
        () => {
          this.showErrorNotification(saveErrorText);
        }
      );
  }

  cloneAlert() {
    this._router.navigate([`/clone/${this.id}`]);
  }

  deleteAlert() {
    const dialogRef = this._dialog.open(DeleteConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._companyService.currentCompany$
          .pipe(
            switchMap((company: Company) => {
              return this._subscriptionApiService.delete(this.id, company.value);
            }),
            take(1)
          )
          .subscribe(
            () => {
              this.alertForm.reset();
              this.showSuccessNotification(deleteSuccessText);
              this._router.navigate(['/']);
            },
            () => {
              this.showErrorNotification(deleteErrorText);
            }
          );
      }
    });
  }

  showSuccessNotification(title: string) {
    this._notifications.openSuccess(title, '', TOAST_MESSAGE_DURATION_SECONDS);
  }

  showErrorNotification(title: string) {
    this._notifications.openError(title, '', TOAST_MESSAGE_DURATION_SECONDS);
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.value == object2.id;
  }

  handleAlertTypeSelection(selectedAlertTypeOption: Record<string, string>[], severityOptionsByType: Record<string, any[]>) {
    const selectedAlertTypeId = selectedAlertTypeOption[0].value;
    if (this.alertTypesWithRadioButtons.includes(selectedAlertTypeId)) {
      if (this.alertForm.value.type !== selectedAlertTypeId) {
        // AAG-158: As the ticket's description, critical is selected by default
        // and we decided to store it at index 0 of the severity options
        const criticalSeverityOptionIndex = 0;
        const defaultSeverityOption = severityOptionsByType[selectedAlertTypeId][criticalSeverityOptionIndex];

        this.alertForm.patchValue({ severity: defaultSeverityOption.value });
      }
    }
  }

  addOrRemoveControlsBasedOnTypeSelection(value) {
    // Add severity control only if alert type has radio buttons
    if (this.alertTypesWithRadioButtons.includes(value)) {
      this.alertForm.addControl('severity', new FormControl('', [Validators.required]));
    } else {
      this.alertForm.removeControl('severity');
    }

    // Add unauthorized usage form control only when Unauthorized Vehicle Movement is selected as alert type
    if (value === this.unauthorizedUsageAlertTypeValue) {
      this.alertForm.addControl('unauthorizedUsageDistance', new FormControl('0', [Validators.required]));
    } else {
      this.alertForm.removeControl('unauthorizedUsageDistance');
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
    this._renderer.removeClass(this.document.body, 'form-screen');
  }

  omitNumbersFromInput(event) {
    var key;
    key = event.charCode;
    return key > 47 && key < 58;
  }

  handleAssetDropdownChange(e) {
    if (e.isAllSelected) {
      this.alertForm.patchValue({ assets: null, allAssets: true });
      this.alertForm.get('assets').clearValidators();
    } else {
      this.alertForm.patchValue({ assets: e.selected.assetIds, allAssets: false });
      this.alertForm.get('assets').setValidators([Validators.required]);
    }
    // Need to manually mark this as touched/dirty since we are now using a hidden form control
    this.alertForm.controls['assets'].markAsTouched();
    this.alertForm.controls['assets'].markAsDirty();
    this.alertForm.get('assets').updateValueAndValidity();
    this.alertForm.updateValueAndValidity();
  }

  addCustomSchedule(data: Record<string, string> = null) {
    const newCustomSchedule = this.fb.group({
      startDay: [data?.startDay || '', Validators.required],
      startTime: [data?.startTime || '', Validators.required],
      endDay: [data?.endDay || '', Validators.required],
      endTime: [data?.endTime || '', Validators.required],
    }, { validators: [this.individualScheduleValidator()] });

    this.customScheduleList.push(newCustomSchedule);
  }

  removeCustomSchedule(index: number) {
    this.customScheduleList.removeAt(index);
    this.alertForm.controls.customSchedules.markAsTouched();
    this.alertForm.controls.customSchedules.markAsDirty();
    this.alertForm.controls.customSchedules.updateValueAndValidity();
  }

  individualScheduleValidator(): ValidatorFn {
    return (formGroup: AbstractControl) => {
      const { startDay, startTime, endDay, endTime } = formGroup.value;
      return startDay && startTime && endDay && endTime && startDay === endDay && startTime === endTime
        ? { duplicateScheduleValue: true }
        : null;
    };
  }

  multipleSchedulesValidator(): ValidatorFn {
    return (formArray: FormArray) => {
      const uniqueSchedules = [];
      for (const { startDay, startTime, endDay, endTime } of formArray.value) {
        const value = `${startDay}${startTime}${endDay}${endTime}`;
        if (uniqueSchedules.find(schedule => schedule === value)) {
          return { duplicateSchedules: true };
        } else {
          uniqueSchedules.push(value);
        }
      }
      return null;
    };
  }
}
